import React from 'react';
import Layout from "../components/layout"
import '../main.scss'

const IndexPage = () => {
  return (
    <Layout>
    </Layout>
  )

}

export default IndexPage
